import React from 'react';
import { Link } from 'react-router-dom';
import { GitBranch, Users, Search, ArrowRight, Menu } from 'lucide-react';
import { useAuthStore } from '../store/useAuthStore';

export default function HomePage() {
  const { isAuthenticated, logout } = useAuthStore();

  return (
    <div className="min-h-screen bg-white">
      {/* Header */}
      <header className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center gap-2">
              <GitBranch className="w-8 h-8 text-green-700" />
              <span className="text-2xl font-bold text-gray-900">Omuganda Kika</span>
            </div>
            
            <nav className="hidden md:flex items-center gap-8">
              <Link to="/features" className="text-gray-600 hover:text-gray-900">Features</Link>
              <Link to="/pricing" className="text-gray-600 hover:text-gray-900">Pricing</Link>
              <Link to="/about" className="text-gray-600 hover:text-gray-900">About</Link>
              <Link to="/contact" className="text-gray-600 hover:text-gray-900">Contact</Link>
            </nav>

            <div className="flex items-center gap-4">
              {isAuthenticated ? (
                <>
                  <Link
                    to="/dashboard"
                    className="text-green-700 hover:text-green-800 font-medium"
                  >
                    Dashboard
                  </Link>
                  <button
                    onClick={logout}
                    className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 rounded-lg"
                  >
                    Sign Out
                  </button>
                </>
              ) : (
                <>
                  <Link
                    to="/login"
                    className="text-gray-700 hover:text-gray-900 font-medium"
                  >
                    Sign In
                  </Link>
                  <Link
                    to="/signup"
                    className="px-4 py-2 text-sm font-medium text-white bg-green-700 rounded-lg hover:bg-green-800"
                  >
                    Get Started
                  </Link>
                </>
              )}
            </div>

            <button className="md:hidden p-2 text-gray-700 hover:bg-gray-50 rounded-lg">
              <Menu className="w-6 h-6" />
            </button>
          </div>
        </div>
      </header>

      {/* Hero Section */}
      <div 
        className="relative h-[600px] bg-cover bg-center"
        style={{
          backgroundImage: 'url("https://images.unsplash.com/photo-1576016770956-debb63d92058?auto=format&fit=crop&q=80&w=3000")'
        }}
      >
        <div className="absolute inset-0 bg-black/50" />
        <div className="relative max-w-7xl mx-auto px-4 h-full flex items-center">
          <div className="max-w-2xl text-white">
            <h1 className="text-5xl font-bold mb-6">Discover Your Ugandan Heritage</h1>
            <p className="text-xl mb-8">Explore and preserve your family's legacy through our comprehensive family tree platform designed specifically for Ugandan genealogy.</p>
            <Link
              to="/signup"
              className="inline-flex items-center px-6 py-3 text-lg font-medium text-white bg-green-700 rounded-lg hover:bg-green-800"
            >
              Start Your Family Tree
              <ArrowRight className="ml-2 w-5 h-5" />
            </Link>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="py-24 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-16">Explore Your Roots</h2>
          <div className="grid md:grid-cols-3 gap-12">
            <div className="text-center">
              <div className="w-16 h-16 mx-auto mb-6 bg-green-100 rounded-full flex items-center justify-center">
                <GitBranch className="w-8 h-8 text-green-700" />
              </div>
              <h3 className="text-xl font-semibold mb-4">Build Your Tree</h3>
              <p className="text-gray-600">Create and explore your family tree with our culturally-aware tools.</p>
            </div>
            <div className="text-center">
              <div className="w-16 h-16 mx-auto mb-6 bg-green-100 rounded-full flex items-center justify-center">
                <Users className="w-8 h-8 text-green-700" />
              </div>
              <h3 className="text-xl font-semibold mb-4">Connect with Clan</h3>
              <p className="text-gray-600">Discover and connect with relatives through our clan matching system.</p>
            </div>
            <div className="text-center">
              <div className="w-16 h-16 mx-auto mb-6 bg-green-100 rounded-full flex items-center justify-center">
                <Search className="w-8 h-8 text-green-700" />
              </div>
              <h3 className="text-xl font-semibold mb-4">Research History</h3>
              <p className="text-gray-600">Access historical records and traditional knowledge of Ugandan families.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}