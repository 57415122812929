import React, { useState } from 'react';
import PersonCard from './PersonCard';
import { useTreeStore } from '../store/useTreeStore';
import AddPersonModal from './modals/AddPersonModal';

export default function StandardFamilyTree() {
  const { 
    people, 
    selectedPersonId, 
    selectPerson, 
    mainMemberId,
    setMainMember,
    getRelatives 
  } = useTreeStore();

  const [addPersonData, setAddPersonData] = useState<{
    isOpen: boolean;
    relationType?: 'child' | 'parent' | 'spouse';
    relatedPersonId?: string;
  }>({
    isOpen: false
  });

  const selectedPerson = people.find(p => p.id === selectedPersonId);
  
  const handleAddRelative = (personId: string, relationType: 'child' | 'parent' | 'spouse') => {
    setAddPersonData({
      isOpen: true,
      relationType,
      relatedPersonId: personId
    });
  };

  if (!selectedPerson) return null;

  const parents = getRelatives(selectedPerson.id, 'parents');
  const siblings = getRelatives(selectedPerson.id, 'siblings');
  const spouses = getRelatives(selectedPerson.id, 'spouses');
  const children = getRelatives(selectedPerson.id, 'children');

  return (
    <div className="p-8">
      <div className="space-y-8">
        {/* Parents Section */}
        {parents.length > 0 && (
          <div>
            <h3 className="text-lg font-medium mb-4">Parents</h3>
            <div className="flex gap-4">
              {parents.map(parent => (
                <PersonCard
                  key={parent.id}
                  person={parent}
                  onAddRelative={(type) => handleAddRelative(parent.id, type)}
                  onSelect={() => selectPerson(parent.id)}
                  isSelected={selectedPersonId === parent.id}
                />
              ))}
            </div>
          </div>
        )}

        {/* Siblings Section */}
        {siblings.length > 0 && (
          <div>
            <h3 className="text-lg font-medium mb-4">Siblings</h3>
            <div className="flex gap-4 flex-wrap">
              {siblings.map(sibling => (
                <PersonCard
                  key={sibling.id}
                  person={sibling}
                  onAddRelative={(type) => handleAddRelative(sibling.id, type)}
                  onSelect={() => selectPerson(sibling.id)}
                  isSelected={selectedPersonId === sibling.id}
                />
              ))}
            </div>
          </div>
        )}

        {/* Selected Person */}
        <div>
          <div className="flex items-center gap-4 mb-4">
            <h3 className="text-lg font-medium">Selected Person</h3>
            {!selectedPerson.isMainMember && (
              <button
                onClick={() => setMainMember(selectedPerson.id)}
                className="px-3 py-1 text-sm font-medium text-green-700 bg-green-100 rounded-full hover:bg-green-200"
              >
                Set as Main Member
              </button>
            )}
          </div>
          <div className="flex gap-4">
            <PersonCard
              person={selectedPerson}
              onAddRelative={(type) => handleAddRelative(selectedPerson.id, type)}
              onSelect={() => selectPerson(selectedPerson.id)}
              isSelected={true}
            />
          </div>
        </div>

        {/* Spouses Section */}
        {spouses.length > 0 && (
          <div>
            <h3 className="text-lg font-medium mb-4">Spouses</h3>
            <div className="flex gap-4 flex-wrap">
              {spouses.map(spouse => (
                <PersonCard
                  key={spouse.id}
                  person={spouse}
                  onAddRelative={(type) => handleAddRelative(spouse.id, type)}
                  onSelect={() => selectPerson(spouse.id)}
                  isSelected={selectedPersonId === spouse.id}
                />
              ))}
            </div>
          </div>
        )}

        {/* Children Section */}
        {children.length > 0 && (
          <div>
            <h3 className="text-lg font-medium mb-4">Children</h3>
            <div className="flex gap-4 flex-wrap">
              {children.map(child => (
                <PersonCard
                  key={child.id}
                  person={child}
                  onAddRelative={(type) => handleAddRelative(child.id, type)}
                  onSelect={() => selectPerson(child.id)}
                  isSelected={selectedPersonId === child.id}
                />
              ))}
            </div>
          </div>
        )}
      </div>

      <AddPersonModal
        isOpen={addPersonData.isOpen}
        onClose={() => setAddPersonData({ isOpen: false })}
        relationType={addPersonData.relationType}
        relatedPersonId={addPersonData.relatedPersonId}
      />
    </div>
  );
}