import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { GitBranch, Users, Clock, FileText, Image, HeartPulse, Search, Share2, Settings, HelpCircle } from 'lucide-react';
import { useAuthStore } from '../store/useAuthStore';

const menuItems = [
  { icon: GitBranch, label: 'Family Tree', path: '/dashboard' },
  { icon: Users, label: 'People', path: '/dashboard/people' },
  { icon: Clock, label: 'Timeline', path: '/dashboard/timeline' },
  { icon: FileText, label: 'Documents', path: '/dashboard/documents' },
  { icon: Image, label: 'Photos', path: '/dashboard/photos' },
  { icon: HeartPulse, label: 'DNA Matches', path: '/dashboard/dna-matches' },
  { icon: Search, label: 'Research', path: '/dashboard/research' }
];

const quickActions = [
  { icon: Share2, label: 'Share Tree', path: '/dashboard/share' },
  { icon: Settings, label: 'Settings', path: '/dashboard/settings' },
  { icon: HelpCircle, label: 'Help', path: '/help' }
];

export default function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const logout = useAuthStore((state) => state.logout);

  const handleNavigation = (path: string) => {
    if (path === '/help') {
      window.open('https://help.omugandakika.com', '_blank');
    } else {
      navigate(path);
    }
  };

  return (
    <div className="w-64 h-screen bg-white border-r border-gray-200 flex flex-col">
      <div className="p-4 border-b border-gray-200">
        <h1 className="text-xl font-semibold flex items-center gap-2 cursor-pointer" onClick={() => navigate('/dashboard')}>
          <GitBranch className="w-6 h-6 text-green-700" />
          Omuganda Kika
        </h1>
      </div>
      
      <nav className="flex-1 overflow-y-auto p-4">
        {menuItems.map((item) => (
          <button
            key={item.label}
            onClick={() => handleNavigation(item.path)}
            className={`w-full flex items-center gap-3 px-3 py-2 rounded-lg mb-1 ${
              location.pathname === item.path
                ? 'bg-green-50 text-green-700' 
                : 'text-gray-700 hover:bg-gray-50'
            }`}
          >
            <item.icon className="w-5 h-5" />
            <span>{item.label}</span>
          </button>
        ))}
      </nav>

      <div className="p-4 border-t border-gray-200">
        <h2 className="text-sm font-medium text-gray-500 mb-2">Quick Actions</h2>
        {quickActions.map((item) => (
          <button
            key={item.label}
            onClick={() => handleNavigation(item.path)}
            className={`w-full flex items-center gap-3 px-3 py-2 rounded-lg mb-1 ${
              location.pathname === item.path
                ? 'bg-green-50 text-green-700' 
                : 'text-gray-700 hover:bg-gray-50'
            }`}
          >
            <item.icon className="w-5 h-5" />
            <span>{item.label}</span>
          </button>
        ))}
      </div>
    </div>
  );
}