import React from 'react';
import { Link } from 'react-router-dom';
import { GitBranch, Users, Clock, FileText, Image, HeartPulse, Search } from 'lucide-react';

export default function FeaturesPage() {
  const features = [
    {
      icon: GitBranch,
      title: 'Family Tree Building',
      description: 'Create and manage your family tree with our intuitive interface'
    },
    {
      icon: Users,
      title: 'Clan Connections',
      description: 'Connect with relatives and explore your extended family network'
    },
    {
      icon: Clock,
      title: 'Timeline View',
      description: 'Visualize your family history through an interactive timeline'
    },
    {
      icon: FileText,
      title: 'Document Storage',
      description: 'Safely store and organize important family documents'
    },
    {
      icon: Image,
      title: 'Photo Gallery',
      description: 'Preserve and share family photos with detailed descriptions'
    },
    {
      icon: HeartPulse,
      title: 'DNA Integration',
      description: 'Connect DNA test results with your family tree'
    },
    {
      icon: Search,
      title: 'Research Tools',
      description: 'Access genealogical research tools and historical records'
    }
  ];

  return (
    <div className="min-h-screen bg-white">
      <header className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 py-4">
          <Link to="/" className="text-green-700 hover:text-green-800 flex items-center gap-2">
            <GitBranch className="w-6 h-6" />
            <span className="text-xl font-bold">Omuganda Kika</span>
          </Link>
        </div>
      </header>

      <main className="max-w-7xl mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold text-center mb-12">Features</h1>
        
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature) => (
            <div key={feature.title} className="p-6 bg-white rounded-lg shadow-md border border-gray-100">
              <feature.icon className="w-8 h-8 text-green-600 mb-4" />
              <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </main>
    </div>
  );
}