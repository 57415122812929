import React from 'react';

export default function DnaMatchesPage() {
  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">DNA Matches</h1>
      <div className="bg-white p-8 rounded-lg shadow-md text-center">
        <p className="text-gray-600">
          DNA matching feature coming soon. This section will help you discover and connect with
          genetic relatives through DNA analysis.
        </p>
      </div>
    </div>
  );
}