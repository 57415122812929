import React from 'react';
import { useTreeStore } from '../store/useTreeStore';

export default function PeoplePage() {
  const people = useTreeStore((state) => state.people);

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">People</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {people.map((person) => (
          <div
            key={person.id}
            className="p-4 bg-white rounded-lg shadow-md border-l-4 border-blue-500"
          >
            <h3 className="font-medium text-lg">{person.name}</h3>
            <p className="text-gray-600">Born: {person.birthDate}</p>
            <p className="text-gray-600">Gender: {person.gender}</p>
          </div>
        ))}
      </div>
    </div>
  );
}