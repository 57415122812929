import React from 'react';
import { Link } from 'react-router-dom';
import { GitBranch, Mail } from 'lucide-react';

export default function PricingPage() {
  return (
    <div className="min-h-screen bg-white">
      <header className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 py-4">
          <Link to="/" className="text-green-700 hover:text-green-800 flex items-center gap-2">
            <GitBranch className="w-6 h-6" />
            <span className="text-xl font-bold">Omuganda Kika</span>
          </Link>
        </div>
      </header>

      <main className="max-w-7xl mx-auto px-4 py-12 text-center">
        <h1 className="text-4xl font-bold mb-6">Custom Pricing Solutions</h1>
        <p className="text-xl text-gray-600 mb-8">
          We offer tailored pricing plans to meet your specific needs.
        </p>
        
        <Link
          to="/contact"
          className="inline-flex items-center px-6 py-3 text-lg font-medium text-white bg-green-600 rounded-lg hover:bg-green-700"
        >
          <Mail className="w-5 h-5 mr-2" />
          Contact for Pricing
        </Link>
      </main>
    </div>
  );
}