import { create } from 'zustand';
import axios from 'axios';
import { Person, Relationship } from '../types';

interface TreeState {
  people: Person[];
  relationships: Relationship[];
  selectedPersonId: string | null;
  mainMemberId: string | null;
  loading: boolean;
  error: string | null;
  viewMode: 'standard' | 'vertical';
  
  fetchPeople: (treeId: string) => Promise<void>;
  addPerson: (person: Omit<Person, 'id'>) => Promise<string>;
  updatePerson: (id: string, updates: Partial<Person>) => Promise<void>;
  deletePerson: (id: string) => Promise<void>;
  addRelationship: (relationship: { person1Id: string; person2Id: string; type: 'parent-child' | 'spouse' }) => void;
  removeRelationship: (id: string) => Promise<void>;
  setMainMember: (id: string) => void;
  selectPerson: (id: string | null) => void;
  getRelatives: (personId: string, relationType: 'parents' | 'children' | 'siblings' | 'spouses') => Person[];
  computeRelationship: (person1Id: string, person2Id: string) => string;
  initializeDemoData: () => void;
  setViewMode: (mode: 'standard' | 'vertical') => void;
}

const demoData: Person[] = [
  {
    id: '1',
    name: 'John Mukasa',
    birthDate: '1960-03-15',
    gender: 'male',
    spouseIds: ['2'],
    childrenIds: ['3', '4'],
    events: [{
      id: '1',
      type: 'birth',
      date: '1960-03-15',
      description: 'Birth'
    }],
    isMainMember: true
  },
  {
    id: '2',
    name: 'Sarah Nakato',
    birthDate: '1965-06-20',
    gender: 'female',
    spouseIds: ['1'],
    childrenIds: ['3', '4'],
    events: [{
      id: '2',
      type: 'birth',
      date: '1965-06-20',
      description: 'Birth'
    }]
  },
  {
    id: '3',
    name: 'David Mukasa',
    birthDate: '1990-09-10',
    gender: 'male',
    fatherId: '1',
    motherId: '2',
    spouseIds: [],
    childrenIds: [],
    events: [{
      id: '3',
      type: 'birth',
      date: '1990-09-10',
      description: 'Birth'
    }]
  },
  {
    id: '4',
    name: 'Grace Mukasa',
    birthDate: '1992-12-05',
    gender: 'female',
    fatherId: '1',
    motherId: '2',
    spouseIds: [],
    childrenIds: [],
    events: [{
      id: '4',
      type: 'birth',
      date: '1992-12-05',
      description: 'Birth'
    }]
  }
];

export const useTreeStore = create<TreeState>((set, get) => ({
  people: [],
  relationships: [],
  selectedPersonId: null,
  mainMemberId: null,
  loading: false,
  error: null,
  viewMode: 'standard',

  fetchPeople: async (treeId) => {
    try {
      set({ loading: true, error: null });
      const response = await axios.get(`/api/persons/tree/${treeId}`);
      set({ people: response.data, loading: false });
    } catch (err) {
      set({ error: 'Failed to fetch people', loading: false });
    }
  },

  addPerson: async (personData) => {
    try {
      set({ loading: true, error: null });
      const id = Math.random().toString(36).substr(2, 9);
      const newPerson: Person = {
        ...personData,
        id,
        spouseIds: [],
        childrenIds: [],
        events: [{
          id: Math.random().toString(36).substr(2, 9),
          type: 'birth',
          date: personData.birthDate,
          description: 'Birth'
        }]
      };

      set((state) => ({
        people: [...state.people, newPerson],
        loading: false
      }));

      return id;
    } catch (err) {
      set({ error: 'Failed to add person', loading: false });
      throw err;
    }
  },

  addRelationship: (relationshipData) => {
    set((state) => {
      const updatedPeople = [...state.people];
      const person1Index = updatedPeople.findIndex(p => p.id === relationshipData.person1Id);
      const person2Index = updatedPeople.findIndex(p => p.id === relationshipData.person2Id);

      if (person1Index === -1 || person2Index === -1) return state;

      const person1 = updatedPeople[person1Index];
      const person2 = updatedPeople[person2Index];

      if (relationshipData.type === 'parent-child') {
        if (person1.gender === 'male') {
          updatedPeople[person2Index] = {
            ...person2,
            fatherId: person1.id
          };
        } else {
          updatedPeople[person2Index] = {
            ...person2,
            motherId: person1.id
          };
        }
        updatedPeople[person1Index] = {
          ...person1,
          childrenIds: [...(person1.childrenIds || []), person2.id]
        };
      } else if (relationshipData.type === 'spouse') {
        updatedPeople[person1Index] = {
          ...person1,
          spouseIds: [...(person1.spouseIds || []), person2.id]
        };
        updatedPeople[person2Index] = {
          ...person2,
          spouseIds: [...(person2.spouseIds || []), person1.id]
        };
      }

      return { people: updatedPeople };
    });
  },

  updatePerson: async (id, updates) => {
    try {
      set({ loading: true, error: null });
      set((state) => ({
        people: state.people.map(p => p.id === id ? { ...p, ...updates } : p),
        loading: false
      }));
    } catch (err) {
      set({ error: 'Failed to update person', loading: false });
      throw err;
    }
  },

  deletePerson: async (id) => {
    try {
      set({ loading: true, error: null });
      set((state) => ({
        people: state.people.filter(p => p.id !== id),
        loading: false
      }));
    } catch (err) {
      set({ error: 'Failed to delete person', loading: false });
      throw err;
    }
  },

  setMainMember: (id) => {
    set((state) => ({
      people: state.people.map(p => ({
        ...p,
        isMainMember: p.id === id
      })),
      mainMemberId: id,
      selectedPersonId: id
    }));
  },

  selectPerson: (id) => {
    set({ selectedPersonId: id });
  },

  getRelatives: (personId, relationType) => {
    const state = get();
    const person = state.people.find(p => p.id === personId);
    if (!person) return [];

    switch (relationType) {
      case 'parents':
        return state.people.filter(p => 
          p.id === person.fatherId || p.id === person.motherId
        );
      case 'children':
        return state.people.filter(p => 
          person.childrenIds?.includes(p.id)
        );
      case 'siblings':
        return state.people.filter(p => 
          (person.fatherId && p.fatherId === person.fatherId) ||
          (person.motherId && p.motherId === person.motherId) &&
          p.id !== person.id
        );
      case 'spouses':
        return state.people.filter(p => 
          person.spouseIds?.includes(p.id)
        );
      default:
        return [];
    }
  },

  computeRelationship: (person1Id, person2Id) => {
    // Implement relationship computation logic
    return 'Unknown';
  },

  setViewMode: (mode) => {
    set({ viewMode: mode });
  },

  initializeDemoData: () => {
    set({
      people: demoData,
      selectedPersonId: '1',
      mainMemberId: '1'
    });
  }
}));