import React, { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { Search, X } from 'lucide-react';
import { useTreeStore } from '../../store/useTreeStore';

interface SearchModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function SearchModal({ isOpen, onClose }: SearchModalProps) {
  const people = useTreeStore((state) => state.people);
  const [searchQuery, setSearchQuery] = useState('');

  const filteredPeople = people.filter(person =>
    person.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 flex items-start justify-center pt-16 px-4">
        <Dialog.Panel className="w-full max-w-2xl bg-white rounded-lg shadow-xl">
          <div className="p-4 border-b border-gray-200">
            <div className="flex items-center gap-2">
              <Search className="w-5 h-5 text-gray-400" />
              <input
                type="text"
                placeholder="Search people..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="flex-1 border-none focus:ring-0 text-lg"
                autoFocus
              />
              <button onClick={onClose}>
                <X className="w-5 h-5 text-gray-400 hover:text-gray-600" />
              </button>
            </div>
          </div>
          
          <div className="max-h-96 overflow-y-auto p-2">
            {filteredPeople.map(person => (
              <div
                key={person.id}
                className="p-3 hover:bg-gray-50 rounded-lg cursor-pointer"
              >
                <div className="font-medium">{person.name}</div>
                <div className="text-sm text-gray-500">Born: {person.birthDate}</div>
              </div>
            ))}
            
            {searchQuery && filteredPeople.length === 0 && (
              <div className="text-center p-4 text-gray-500">
                No results found for "{searchQuery}"
              </div>
            )}
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}