import React from 'react';
import { useTreeStore } from '../store/useTreeStore';
import PersonCard from './PersonCard';

export default function VerticalFamilyTree() {
  const { 
    people, 
    selectedPersonId, 
    selectPerson,
    getRelatives,
    setMainMember 
  } = useTreeStore();

  const selectedPerson = people.find(p => p.id === selectedPersonId);
  if (!selectedPerson) return null;

  const parents = getRelatives(selectedPerson.id, 'parents');
  const grandparents = parents.flatMap(parent => 
    getRelatives(parent.id, 'parents')
  );
  const children = getRelatives(selectedPerson.id, 'children');
  const spouse = getRelatives(selectedPerson.id, 'spouses')[0]; // Get primary spouse

  return (
    <div className="p-8 min-h-full overflow-auto">
      <div className="relative flex flex-col items-center">
        {/* Great Grandparents Level */}
        <div className="mb-16">
          <div className="flex justify-center gap-32">
            {grandparents.map(grandparent => (
              <div key={grandparent.id} className="flex flex-col items-center">
                <PersonCard
                  person={grandparent}
                  onSelect={() => selectPerson(grandparent.id)}
                  isSelected={selectedPersonId === grandparent.id}
                  onAddRelative={() => {}}
                />
                {/* Vertical line down */}
                <div className="h-8 w-px bg-gray-300 my-2"></div>
              </div>
            ))}
          </div>
        </div>

        {/* Parents Level */}
        <div className="mb-16">
          <div className="flex justify-center gap-32">
            {parents.map(parent => (
              <div key={parent.id} className="flex flex-col items-center">
                <PersonCard
                  person={parent}
                  onSelect={() => selectPerson(parent.id)}
                  isSelected={selectedPersonId === parent.id}
                  onAddRelative={() => {}}
                />
                {/* Vertical line down */}
                <div className="h-8 w-px bg-gray-300 my-2"></div>
              </div>
            ))}
          </div>
          {/* Horizontal line connecting parents */}
          {parents.length === 2 && (
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-32 h-px bg-gray-300"></div>
          )}
        </div>

        {/* Main Person and Spouse Level */}
        <div className="mb-16">
          <div className="flex justify-center gap-8 items-center">
            <PersonCard
              person={selectedPerson}
              onSelect={() => selectPerson(selectedPerson.id)}
              isSelected={true}
              onAddRelative={() => {}}
            />
            {spouse && (
              <>
                {/* Horizontal line connecting spouses */}
                <div className="w-8 h-px bg-gray-300"></div>
                <PersonCard
                  person={spouse}
                  onSelect={() => selectPerson(spouse.id)}
                  isSelected={selectedPersonId === spouse.id}
                  onAddRelative={() => {}}
                />
              </>
            )}
          </div>
          {/* Vertical line down to children */}
          {children.length > 0 && (
            <div className="h-8 w-px bg-gray-300 mx-auto my-2"></div>
          )}
        </div>

        {/* Children Level */}
        {children.length > 0 && (
          <div>
            <div className="flex justify-center gap-8">
              {children.map(child => (
                <div key={child.id} className="flex flex-col items-center">
                  <PersonCard
                    person={child}
                    onSelect={() => selectPerson(child.id)}
                    isSelected={selectedPersonId === child.id}
                    onAddRelative={() => {}}
                  />
                </div>
              ))}
            </div>
            {/* Horizontal line connecting children */}
            {children.length > 1 && (
              <div className="absolute bottom-1/4 left-1/2 transform -translate-x-1/2 w-full max-w-md h-px bg-gray-300"></div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}