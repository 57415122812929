import React from 'react';
import { useTreeStore } from '../store/useTreeStore';

export default function TimelinePage() {
  const people = useTreeStore((state) => state.people);
  const sortedEvents = [...people]
    .sort((a, b) => new Date(a.birthDate).getTime() - new Date(b.birthDate).getTime());

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Timeline</h1>
      <div className="relative border-l-2 border-gray-200 ml-4">
        {sortedEvents.map((person) => (
          <div key={person.id} className="mb-8 ml-6">
            <div className="absolute w-3 h-3 bg-blue-500 rounded-full -left-[7px]"></div>
            <div className="bg-white p-4 rounded-lg shadow-md">
              <time className="text-sm font-semibold text-blue-500">{person.birthDate}</time>
              <h3 className="text-lg font-medium mt-1">{person.name} was born</h3>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}