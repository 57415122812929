import React from 'react';
import { Link } from 'react-router-dom';
import { GitBranch } from 'lucide-react';

export default function AboutPage() {
  return (
    <div className="min-h-screen bg-white">
      <header className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 py-4">
          <Link to="/" className="text-green-700 hover:text-green-800 flex items-center gap-2">
            <GitBranch className="w-6 h-6" />
            <span className="text-xl font-bold">Omuganda Kika</span>
          </Link>
        </div>
      </header>

      <main className="max-w-3xl mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold mb-8">About Omuganda Kika</h1>
        
        <div className="prose prose-lg">
          <p className="text-gray-600 mb-6">
            Omuganda Kika is a specialized family tree platform designed specifically for Ugandan 
            genealogy. Our mission is to help families preserve their heritage, connect with relatives, 
            and explore their ancestral roots.
          </p>

          <h2 className="text-2xl font-bold mt-8 mb-4">Our Vision</h2>
          <p className="text-gray-600 mb-6">
            We envision a world where every Ugandan family can easily trace their lineage, preserve 
            their stories, and pass down their cultural heritage to future generations.
          </p>

          <h2 className="text-2xl font-bold mt-8 mb-4">Why Choose Us</h2>
          <ul className="list-disc pl-6 text-gray-600 space-y-2">
            <li>Specialized focus on Ugandan family structures and naming conventions</li>
            <li>User-friendly interface designed for all age groups</li>
            <li>Secure storage of family data and documents</li>
            <li>Continuous development of new features based on user feedback</li>
            <li>Dedicated support team to assist with your genealogy journey</li>
          </ul>
        </div>
      </main>
    </div>
  );
}