import React from 'react';

export default function ResearchPage() {
  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Research</h1>
      <div className="bg-white p-8 rounded-lg shadow-md text-center">
        <p className="text-gray-600">
          Research tools coming soon. This section will provide resources and tools for
          genealogical research and family history discovery.
        </p>
      </div>
    </div>
  );
}