import React, { useState, useEffect, useRef } from 'react';
import { useTreeStore } from '../store/useTreeStore';
import StandardFamilyTree from './StandardFamilyTree';
import VerticalFamilyTree from './VerticalFamilyTree';
import { LayoutGrid, GitBranchPlus, ZoomIn, ZoomOut, Move } from 'lucide-react';
import AddPersonModal from './modals/AddPersonModal';

export default function FamilyTree() {
  const { viewMode, setViewMode, people, initializeDemoData } = useTreeStore();
  const [isAddPersonOpen, setIsAddPersonOpen] = useState(false);
  const [isPanning, setIsPanning] = useState(false);
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const containerRef = useRef<HTMLDivElement>(null);
  const lastMousePos = useRef({ x: 0, y: 0 });

  useEffect(() => {
    if (people.length === 0) {
      initializeDemoData();
    }
  }, []);

  const handleMouseDown = (e: React.MouseEvent) => {
    if (isPanning) {
      lastMousePos.current = { x: e.clientX, y: e.clientY };
      containerRef.current?.addEventListener('mousemove', handleMouseMove);
      containerRef.current?.addEventListener('mouseup', handleMouseUp);
    }
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (isPanning && containerRef.current) {
      const dx = e.clientX - lastMousePos.current.x;
      const dy = e.clientY - lastMousePos.current.y;
      setPosition(prev => ({ x: prev.x + dx, y: prev.y + dy }));
      lastMousePos.current = { x: e.clientX, y: e.clientY };
    }
  };

  const handleMouseUp = () => {
    containerRef.current?.removeEventListener('mousemove', handleMouseMove);
    containerRef.current?.removeEventListener('mouseup', handleMouseUp);
  };

  const handleZoomIn = () => {
    setScale(prev => Math.min(prev + 0.1, 2));
  };

  const handleZoomOut = () => {
    setScale(prev => Math.max(prev - 0.1, 0.5));
  };

  if (people.length === 0) {
    return (
      <div className="p-8 text-center">
        <p className="text-gray-600 mb-4">Start building your family tree by adding the main member</p>
        <button
          onClick={() => setIsAddPersonOpen(true)}
          className="px-4 py-2 text-sm font-medium text-white bg-green-600 rounded-md hover:bg-green-700"
        >
          Add Main Member
        </button>
        <AddPersonModal 
          isOpen={isAddPersonOpen} 
          onClose={() => setIsAddPersonOpen(false)} 
        />
      </div>
    );
  }

  return (
    <div>
      <div className="p-4 border-b border-gray-200 bg-white flex justify-between">
        <div className="flex gap-2">
          <button
            onClick={() => setViewMode('standard')}
            className={`p-2 rounded-lg ${
              viewMode === 'standard' ? 'bg-green-50 text-green-600' : 'text-gray-600 hover:bg-gray-50'
            }`}
          >
            <LayoutGrid className="w-5 h-5" />
          </button>
          <button
            onClick={() => setViewMode('vertical')}
            className={`p-2 rounded-lg ${
              viewMode === 'vertical' ? 'bg-green-50 text-green-600' : 'text-gray-600 hover:bg-gray-50'
            }`}
          >
            <GitBranchPlus className="w-5 h-5" />
          </button>
        </div>

        <div className="flex gap-2">
          <button
            onClick={handleZoomIn}
            className="p-2 rounded-lg text-gray-600 hover:bg-gray-50"
          >
            <ZoomIn className="w-5 h-5" />
          </button>
          <button
            onClick={handleZoomOut}
            className="p-2 rounded-lg text-gray-600 hover:bg-gray-50"
          >
            <ZoomOut className="w-5 h-5" />
          </button>
          <button
            onClick={() => setIsPanning(!isPanning)}
            className={`p-2 rounded-lg ${
              isPanning ? 'bg-green-50 text-green-600' : 'text-gray-600 hover:bg-gray-50'
            }`}
          >
            <Move className="w-5 h-5" />
          </button>
        </div>
      </div>

      <div
        ref={containerRef}
        className="overflow-hidden h-[calc(100vh-8rem)]"
        onMouseDown={handleMouseDown}
        style={{ cursor: isPanning ? 'move' : 'default' }}
      >
        <div
          style={{
            transform: `translate(${position.x}px, ${position.y}px) scale(${scale})`,
            transformOrigin: '0 0',
            transition: 'transform 0.1s ease-out'
          }}
        >
          {viewMode === 'standard' ? <StandardFamilyTree /> : <VerticalFamilyTree />}
        </div>
      </div>
    </div>
  );
}