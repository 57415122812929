import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface User {
  id: string;
  email: string;
  name: string;
}

interface AuthState {
  user: User | null;
  isAuthenticated: boolean;
  login: (email: string, password: string) => Promise<void>;
  signup: (name: string, email: string, password: string) => Promise<void>;
  logout: () => void;
}

// Sample users database
const sampleUsers = [
  {
    id: '1',
    email: 'desirenjalwe@gmail.com',
    name: 'Desire Njalwe',
    password: 'password123'
  },
  {
    id: '2',
    email: 'goldenxps559@gmail.com',
    name: 'Demo User',
    password: 'demo123'
  }
];

export const useAuthStore = create<AuthState>()(
  persist(
    (set) => ({
      user: null,
      isAuthenticated: false,
      login: async (email, password) => {
        await new Promise(resolve => setTimeout(resolve, 500));
        
        const user = sampleUsers.find(u => u.email === email && u.password === password);
        
        if (!user) {
          throw new Error('Invalid credentials');
        }
        
        const { password: _, ...userWithoutPassword } = user;
        set({ user: userWithoutPassword, isAuthenticated: true });
      },
      signup: async (name, email, password) => {
        await new Promise(resolve => setTimeout(resolve, 500));
        
        if (sampleUsers.some(u => u.email === email)) {
          throw new Error('User already exists');
        }
        
        const newUser = {
          id: String(sampleUsers.length + 1),
          email,
          name,
          password
        };
        
        sampleUsers.push(newUser);
        
        const { password: _, ...userWithoutPassword } = newUser;
        set({ user: userWithoutPassword, isAuthenticated: true });
      },
      logout: () => {
        set({ user: null, isAuthenticated: false });
      },
    }),
    {
      name: 'auth-storage',
    }
  )
);