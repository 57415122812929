import React from 'react';

export default function PhotosPage() {
  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Photos</h1>
      <div className="bg-white p-8 rounded-lg shadow-md text-center">
        <p className="text-gray-600">
          Photo gallery feature coming soon. This section will allow you to upload and organize
          family photos with tags and descriptions.
        </p>
      </div>
    </div>
  );
}