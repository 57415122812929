import React from 'react';
import { User, Plus, MoreVertical } from 'lucide-react';
import { Menu } from '@headlessui/react';
import { Person } from '../types';

interface PersonCardProps {
  person: Person;
  onAddRelative: (relationType: 'child' | 'parent' | 'spouse') => void;
  onSelect: () => void;
  isSelected: boolean;
}

export default function PersonCard({ person, onAddRelative, onSelect, isSelected }: PersonCardProps) {
  return (
    <div 
      className={`w-40 p-3 rounded-lg shadow-md bg-white border-2 transition-colors cursor-pointer ${
        isSelected ? 'border-green-500' : 'border-transparent hover:border-gray-200'
      }`}
      onClick={onSelect}
    >
      <div className="flex flex-col items-center">
        <div className={`w-16 h-16 rounded-full bg-gray-100 flex items-center justify-center mb-2 ${
          person.gender === 'male' ? 'bg-blue-50' : 'bg-pink-50'
        }`}>
          <User className={`w-8 h-8 ${
            person.gender === 'male' ? 'text-blue-500' : 'text-pink-500'
          }`} />
        </div>
        
        <div className="text-center">
          <h3 className="font-medium text-sm">{person.name}</h3>
          <p className="text-xs text-gray-500">
            {person.birthDate}
            {person.deathDate ? ` - ${person.deathDate}` : ''}
          </p>
        </div>
      </div>

      <Menu as="div" className="absolute top-1 right-1">
        <Menu.Button className="p-1 rounded-full hover:bg-gray-100">
          <MoreVertical className="w-4 h-4 text-gray-500" />
        </Menu.Button>
        
        <Menu.Items className="absolute right-0 mt-1 w-48 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
          <div className="p-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => onAddRelative('child')}
                  className={`${
                    active ? 'bg-gray-100' : ''
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                >
                  <Plus className="w-4 h-4 mr-2" />
                  Add Child
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => onAddRelative('parent')}
                  className={`${
                    active ? 'bg-gray-100' : ''
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                >
                  <Plus className="w-4 h-4 mr-2" />
                  Add Parent
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => onAddRelative('spouse')}
                  className={`${
                    active ? 'bg-gray-100' : ''
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                >
                  <Plus className="w-4 h-4 mr-2" />
                  Add Spouse
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Menu>
    </div>
  );
}