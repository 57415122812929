import React, { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { X } from 'lucide-react';
import { useTreeStore } from '../../store/useTreeStore';

interface AddPersonModalProps {
  isOpen: boolean;
  onClose: () => void;
  relationType?: 'child' | 'parent' | 'spouse';
  relatedPersonId?: string;
}

export default function AddPersonModal({ 
  isOpen, 
  onClose, 
  relationType,
  relatedPersonId 
}: AddPersonModalProps) {
  const { addPerson, addRelationship, people } = useTreeStore();
  const [formData, setFormData] = useState({
    name: '',
    birthDate: '',
    deathDate: '',
    gender: 'male' as 'male' | 'female'
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const newPersonId = addPerson(formData);

    if (relationType && relatedPersonId) {
      const relatedPerson = people.find(p => p.id === relatedPersonId);
      
      if (relationType === 'child') {
        addRelationship({
          person1Id: relatedPersonId,
          person2Id: newPersonId,
          type: 'parent-child'
        });
      } else if (relationType === 'parent') {
        addRelationship({
          person1Id: newPersonId,
          person2Id: relatedPersonId,
          type: 'parent-child'
        });
      } else if (relationType === 'spouse') {
        addRelationship({
          person1Id: relatedPersonId,
          person2Id: newPersonId,
          type: 'spouse'
        });
      }
    }

    onClose();
    setFormData({ name: '', birthDate: '', deathDate: '', gender: 'male' });
  };

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="w-full max-w-md bg-white rounded-lg p-6">
          <div className="flex justify-between items-center mb-4">
            <Dialog.Title className="text-lg font-medium">
              Add New {relationType ? `${relationType.charAt(0).toUpperCase() + relationType.slice(1)}` : 'Person'}
            </Dialog.Title>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
              <X className="w-5 h-5" />
            </button>
          </div>

          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Name</label>
              <input
                type="text"
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Birth Date</label>
              <input
                type="date"
                value={formData.birthDate}
                onChange={(e) => setFormData({ ...formData, birthDate: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Death Date (Optional)</label>
              <input
                type="date"
                value={formData.deathDate}
                onChange={(e) => setFormData({ ...formData, deathDate: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Gender</label>
              <select
                value={formData.gender}
                onChange={(e) => setFormData({ ...formData, gender: e.target.value as 'male' | 'female' })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
              >
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>

            <div className="flex justify-end gap-3">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-sm font-medium text-white bg-green-600 rounded-md hover:bg-green-700"
              >
                Add Person
              </button>
            </div>
          </form>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}