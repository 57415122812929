import React, { useState } from 'react';
import { Copy, Mail, Link as LinkIcon } from 'lucide-react';

export default function ShareTreePage() {
  const [copied, setCopied] = useState(false);
  const shareUrl = window.location.origin + '/shared-tree/' + 'demo-id';

  const handleCopy = async () => {
    await navigator.clipboard.writeText(shareUrl);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="p-6 max-w-4xl mx-auto">
      <h1 className="text-2xl font-bold mb-6">Share Your Family Tree</h1>

      <div className="bg-white rounded-lg shadow divide-y divide-gray-200">
        {/* Share Link Section */}
        <div className="p-6">
          <h2 className="text-lg font-medium mb-4">Share via Link</h2>
          <div className="flex gap-2">
            <input
              type="text"
              value={shareUrl}
              readOnly
              className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
            />
            <button
              onClick={handleCopy}
              className="px-4 py-2 text-sm font-medium text-white bg-green-600 rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 flex items-center gap-2"
            >
              <Copy className="w-4 h-4" />
              {copied ? 'Copied!' : 'Copy'}
            </button>
          </div>
        </div>

        {/* Share via Email Section */}
        <div className="p-6">
          <h2 className="text-lg font-medium mb-4">Share via Email</h2>
          <form className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Email Addresses</label>
              <input
                type="text"
                placeholder="Enter email addresses separated by commas"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Message (optional)</label>
              <textarea
                rows={3}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                placeholder="Add a personal message..."
              />
            </div>
            <button
              type="submit"
              className="px-4 py-2 text-sm font-medium text-white bg-green-600 rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 flex items-center gap-2"
            >
              <Mail className="w-4 h-4" />
              Send Invitation
            </button>
          </form>
        </div>

        {/* Share Settings */}
        <div className="p-6">
          <h2 className="text-lg font-medium mb-4">Sharing Settings</h2>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Access Level</label>
              <select className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500">
                <option>View Only</option>
                <option>Can Edit</option>
                <option>Full Access</option>
              </select>
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                id="allow-download"
                className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-500"
              />
              <label htmlFor="allow-download" className="ml-2 block text-sm text-gray-900">
                Allow data download
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}