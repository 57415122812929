import React from 'react';

export default function DocumentsPage() {
  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Documents</h1>
      <div className="bg-white p-8 rounded-lg shadow-md text-center">
        <p className="text-gray-600">
          Document management feature coming soon. This section will allow you to store and organize
          family records, certificates, and other important documents.
        </p>
      </div>
    </div>
  );
}