import React, { useState } from 'react';
import { MousePointer2, UserPlus, Heart, Move, ZoomIn, Search, Settings } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import AddPersonModal from './modals/AddPersonModal';
import AddRelationshipModal from './modals/AddRelationshipModal';
import SearchModal from './modals/SearchModal';

const tools = [
  { icon: MousePointer2, label: 'Select', action: 'select' },
  { icon: UserPlus, label: 'Add Person', action: 'addPerson' },
  { icon: Heart, label: 'Add Relationship', action: 'addRelationship' },
  { icon: Move, label: 'Pan', action: 'pan' },
  { icon: ZoomIn, label: 'Zoom', action: 'zoom' }
];

export default function Toolbar() {
  const navigate = useNavigate();
  const [activeAction, setActiveAction] = useState<string>('select');
  const [isAddPersonOpen, setIsAddPersonOpen] = useState(false);
  const [isAddRelationshipOpen, setIsAddRelationshipOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const handleToolClick = (action: string) => {
    setActiveAction(action);
    
    switch (action) {
      case 'addPerson':
        setIsAddPersonOpen(true);
        break;
      case 'addRelationship':
        setIsAddRelationshipOpen(true);
        break;
      case 'pan':
        // Enable panning mode
        break;
      case 'zoom':
        // Enable zoom mode
        break;
      default:
        // Handle selection mode
        break;
    }
  };

  return (
    <>
      <div className="h-16 border-b border-gray-200 bg-white flex items-center justify-between px-4">
        <div className="flex items-center gap-2">
          {tools.map((tool) => (
            <button
              key={tool.label}
              onClick={() => handleToolClick(tool.action)}
              className={`flex flex-col items-center px-3 py-1 rounded-lg ${
                activeAction === tool.action
                  ? 'bg-green-50 text-green-600' 
                  : 'text-gray-700 hover:bg-gray-50'
              }`}
            >
              <tool.icon className="w-5 h-5" />
              <span className="text-xs mt-1">{tool.label}</span>
            </button>
          ))}
        </div>

        <div className="flex items-center gap-3">
          <button 
            onClick={() => setIsSearchOpen(true)}
            className="p-2 text-gray-700 hover:bg-gray-50 rounded-lg"
          >
            <Search className="w-5 h-5" />
          </button>
          <button 
            onClick={() => navigate('/dashboard/settings')}
            className="p-2 text-gray-700 hover:bg-gray-50 rounded-lg"
          >
            <Settings className="w-5 h-5" />
          </button>
        </div>
      </div>

      <AddPersonModal 
        isOpen={isAddPersonOpen} 
        onClose={() => setIsAddPersonOpen(false)} 
      />
      
      <AddRelationshipModal 
        isOpen={isAddRelationshipOpen} 
        onClose={() => setIsAddRelationshipOpen(false)} 
      />

      <SearchModal
        isOpen={isSearchOpen}
        onClose={() => setIsSearchOpen(false)}
      />
    </>
  );
}